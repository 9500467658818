<!--  -->
<template>
  <div class="page">
    <!-- <TopWrapper
      :topObj="{
        bgColor: '#fff',
        title: '反馈',
        txtColor: '#333',
        isBack: true,
        isMsg: false,
      }"
    /> -->
    <van-field
      v-model="form.title"
      placeholder="请输入标题"
      label="反馈标题"
      maxlength="50"
      required
    />
    <van-field
      v-model="form.content"
      rows="4"
      autosize
      label="留言"
      type="textarea"
      maxlength="200"
      placeholder="请输入反馈内容，200字以内"
      show-word-limit
      required
    />
    <div class="label">反馈图片</div>
    <div class="upload-box">
      <van-uploader
        v-model="fileList"
        :max-size="2 * 1024 * 1024"
        :before-read="beforeRead"
        :after-read="afterRead"
        @oversize="onOversize"
        class="upload"
        :max-count="3"
      />
    </div>
    <van-button
      :loading="loading"
      @click="confirm"
      class="button"
      loading-text="提交中..."
      >提交</van-button
    >
  </div>
</template>

<script>
import { feedbackPost } from "@/api/staff";
import { Toast } from "vant";

import Schema from "async-validator";
import { uploadImg } from "@/api/cdn";
import { template } from "lodash";
import TopWrapper from "@/components/topWrapper/index.vue";

const defaltForm = {
  content: "",
  title: "",
};
export default {
  filters: {},
  data() {
    return {
      form: Object.assign({}, defaltForm),
      backgroundIndex: 0,
      fileList: [],
      rules: {
        title: [
          {
            required: true,
            message: "请输入反馈标题",
          },
        ],
        content: [
          {
            required: true,
            message: "请输入反馈内容",
          },
        ],
      },
      validator: null,
      loading: false,
    };
  },

  components: { TopWrapper },

  computed: {},
  mounted() {
    this.initValidator();
  },

  methods: {
    confirm() {
      this.validate();
    },

    /*
     *@des: 生成校验
     *@params:
     *@return:
     */
    initValidator() {
      this.validator = new Schema(this.rules);
    },
    validate() {
      if (this.validator) {
        this.validator
          .validate({
            title: this.form.title,
            content: this.form.content,
          })
          .then((res) => {
            let imgArr = this.fileList.map((item) => {
              return item.resData.fileUrl;
            });

            let imgMaterialCodes = imgArr.join(",");

            this.loading = true;
            feedbackPost({
              ...this.form,
              imgMaterialCodes,
            }).then((res) => {
              Toast("反馈成功");
              this.loading = false;
              this.$router.go(-1);
            });
          })
          .catch((e) => {
            this.loading = false;
            Toast(e.errors[0].message);
          });
      }
    },
    onOversize(file) {
      Toast("文件大小不能超过 2M");
    },
    // 返回布尔值
    beforeRead(file) {
      if (file.type == "image/jpeg" || file.type == "image/png") {
        return true;
      }
      Toast("请上传 jpg/png 格式图片");
      return false;
    },

    afterRead(file) {
      let params = new FormData();
      params.append("file", file.file);
      Toast.loading({
        message: "文件上传中...",
        forbidClick: true,
        duration: 0,
      });
      uploadImg(params).then((res) => {
        Toast.clear();
        Toast("上传成功");
        file.resData = res.data;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  min-height: 100%;
  padding: 0.3rem;
  background: #fafafa;
}
.upload-box {
  background: #fff;
  padding: 0.2rem;
}
.label {
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  font-size: 0.28rem;
}
.button {
  width: 6.9rem;
  height: 0.8rem;
  background: #0094ee;
  border-radius: 0.4rem;
  font-size: 0.26rem;
  color: #ffffff;
  line-height: 0.37rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
}
</style>
